const AMMONIT_TOGGLE = document.querySelector('[data-el="sidebar-toggle"]');
const AMMONIT_SIDEBAR = document.querySelector('[data-el="sidebar"]');
const AMMONIT_OVERLAY = document.querySelector('[data-el="sidebar-overlay"]');

function toggleSidebar(toggle, el, overlay) {
  toggle.classList.toggle('is-active');
  el.classList.toggle('is-active');
  overlay.classList.toggle('is-active');
}

if (!!AMMONIT_TOGGLE && !!AMMONIT_SIDEBAR && !!AMMONIT_OVERLAY) {
  AMMONIT_TOGGLE.addEventListener('click', () => toggleSidebar(AMMONIT_TOGGLE, AMMONIT_SIDEBAR, AMMONIT_OVERLAY));
  AMMONIT_OVERLAY.addEventListener('click', () => toggleSidebar(AMMONIT_TOGGLE, AMMONIT_SIDEBAR, AMMONIT_OVERLAY));
}
