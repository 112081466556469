const BACK_TO_TOP_BUTTON = document.querySelector('#back-to-top');

function goTop() {
  window.scroll({
    top: 0, 
    left: 0, 
    behavior: 'smooth'
  });
};


function toggleBackToTopButton(el) {
  if (pageYOffset > document.documentElement.clientHeight) {
    el.classList.add('active');
  } else {
    el.classList.remove('active');
  }
}

if (!!BACK_TO_TOP_BUTTON) {
  window.addEventListener('scroll', () => toggleBackToTopButton(BACK_TO_TOP_BUTTON));
  BACK_TO_TOP_BUTTON.addEventListener('click', goTop);
}
